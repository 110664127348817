<template>
  <div class="container" id="contestrules">
    <img
      v-if="!isMobile"
      class="rules-bg1"
      src="../assets/images/rules_bg1.png"
      alt=""
      srcset=""
    />
    <img
      v-if="!isMobile"
      class="rules-bg2"
      src="../assets/images/rules_bg2.png"
      alt=""
      srcset=""
    />
    <img
      v-if="!isMobile"
      class="rules-bg3"
      src="../assets/images/rules_bg3.png"
      alt=""
      srcset=""
    />
    <div class="title-box">
      <div class="title-en">CONTEST RULES</div>
      <div class="title-zh">競賽辦法</div>
    </div>

    <div class="content-box">
      <div class="content-title">參賽對象</div>
      <ul>
        <li>參賽企業須設籍於台灣（含離島），資本額與設立日期不限。</li>
        <li>參賽企業須提供無退票證明及無其他不良商業紀錄之保證。</li>
        <li>
          參賽企業須於報名期間繳交本競賽制式報名表一份及不限格式之企業簡介一份。
        </li>
        <li>
          參賽企業於本競賽期間仍可自由發展商業夥伴或參與其他公民營單位舉辦之商業競賽，不因參與本競賽而有任何限制。
        </li>
        <li>
          主辦單位得視實際報名情況，依照本競賽之宗旨及報名企業之條件進行初步篩選，並將篩選之結果通知報名企業。
        </li>
        <li>
          曾獲獎公司，三年內不可再報名，也不可換組別報。2023年、2022年、2021年(國際組)獲獎公司，今年無法報名參賽。
        </li>
      </ul>

      <div class="hint">
        ※本競賽過程中，財團法人宏碁基金會及本競賽評審團將確保參賽企業所提交之商業機密不外流。<br />
        ※主辦單位有最終修改、變更、競賽解釋及取消本競賽的權利，若有相關異動將公告於活動網站。
      </div>

      <div class="img-box" v-if="!isMobile">
        <!-- <img
          class="img1"
          src="../assets/images/rules_img1.png"
          alt=""
          srcset=""
        /> -->
        <img
          class="img2"
          src="../assets/images/rules_img2.png"
          alt=""
          srcset=""
        />
      </div>

      <div class="img-box-moblie" v-if="isMobile">
        <img
          class="img1"
          src="../assets/images/rules_img1_moblie.png"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <div class="content-box">
      <div class="content-title">獎勵辦法</div>
      <div class="content">國際組及永續組各選出前三獎</div>
      <ul>
        <li>首獎獎金新台幣150萬元</li>
        <li>貳獎獎金新台幣80萬元</li>
        <li>參獎獎金新台幣50萬元</li>
      </ul>
      <div class="hint">
        ※
        獲獎企業應於評選結果公布日起至2024年11月29日止，提交經濟部所核發之公司登記證明書影本（或公司變更登記表）及獲獎企業帳戶之存摺影本予主辦單位，以便辦理獎金撥付手續。如獲獎企業未於前述截止日前提交完整且正確之請款文件，視為獲獎企業放棄獎金及請領獎金的權利。
        <br />
        ※ 獲獎企業最終所獲得之獎金金額將係主辦單位依法辦理所得稅扣繳後之金額。
      </div>
      <div class="img-box-moblie" v-if="isMobile">
        <img
          class="img2"
          src="../assets/images/rules_img2_moblie.png"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContestRules",
  data() {
    return {
      isMobile: true,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
.container {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 3vw;
  overflow: hidden;
  flex-direction: column;
  @media all and (max-width: 1024px) {
    padding-bottom: 6vw;
  }
  .rules-bg1 {
    position: absolute;
    left: 0px;
    width: 17%;
    top: 0px;
  }

  .rules-bg2 {
    position: absolute;
    right: 0px;
    width: 25%;
    bottom: 22vw;
  }

  .rules-bg3 {
    position: absolute;
    left: -10vw;
    width: 25%;
    bottom: 0px;
  }

  .title-box {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    @media all and (max-width: 1024px) {
      width: 85%;
      flex-direction: column;
      margin: 0 auto;
      margin-bottom: 8vw;
    }
    .title-zh {
      @include titleZH();
      writing-mode: horizontal-tb;
      text-align: right;
      display: flex;
      justify-content: right;
      @media all and (max-width: 1024px) {
        justify-content: center;
        text-align: center;
      }
      &::before {
        content: "";
        width: 5vw;
        display: inline-block;
        margin-right: 1vw;
        border-top: 1px solid #fff;
        align-self: center;
        @media all and (max-width: 1920px) {
          width: 9vw;
        }

        @media all and (max-width: 1024px) {
          content: "";
          width: 28vw;
          margin-right: 6vw;
          border-top: 1px solid #fff;
          align-self: center;
        }
      }
    }

    .title-en {
      @include titleEN();
      writing-mode: horizontal-tb;
      text-align: right;

      @media all and (max-width: 1024px) {
        text-align: center;
      }
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    margin-top: 3vw;
    @media all and (max-width: 1024px) {
      width: 85%;
      margin: 0 auto;
      padding-top: 0vw;
    }
    .content-title {
      display: flex;
      @include subTitle();
      margin-bottom: 1vw;

      @media all and (max-width: 1024px) {
        margin-bottom: 3vw;
      }
    }
    .content {
      @include content();
      margin-bottom: 0.8vw;
    }
    li {
      display: list-item;
      list-style: disc outside none;
      margin-left: 1vw;
      @include content();

      @media all and (max-width: 1024px) {
        margin-left: 5vw;
        margin-bottom: 1vw;
      }
    }

    .hint {
      background-color: #506664;
      @include content();
      color: #bedeb5;
      padding: 0.7vw 1vw;
      border-radius: 0.5vw;
      margin-top: 1vw;
      @media all and (max-width: 1440px) {
        padding: 1.2vw 1.5vw;
      }

      @media all and (max-width: 1024px) {
        border-radius: 1.5vw;
        padding: 3vw 4vw;
        margin-top: 4vw;
      }
    }

    .img-box {
      display: flex;
      justify-content: space-between;
      margin-top: 3vw;

      @media all and (max-width: 1024px) {
        margin-top: 6vw;
      }

      img {
        height: 19vw;
      }
    }

    .img-box-moblie {
      display: flex;
      justify-content: space-between;
      margin-top: 6vw;
      margin-bottom: 10vw;
      img {
        width: 100%;
      }
    }
  }
}
</style>
